<template>
  <DashboardContainer>
    <Message />
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import Message from "../components/message/message";

export default {
  name: "chat",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
    Message,
  },
};
</script>
