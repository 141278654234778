<template>
  <div class="col-md-12">
    <div class="message-area">
      <div class="msg-search">
        <input type="text" class="form-control" v-model="searchValue" placeholder="Search" v-on:keyup="search" />
        <img class="img-fluid sea-icon" src="../../assets/img/search.svg" alt="" />
      </div>
      <ul class="nav nav-tabs">
        <li>
          <a class="nav-link sms" :class="$route.query.tab == 'chat' ? 'active' : ''" id="email-tab" data-toggle="tab"
            href="#agent" role="tab" aria-controls="email" @click="gettab('agent')" aria-selected="true">
            Users</a>
        </li>
        <li>
          <a class="nav-link sms" id="email-tab" data-toggle="tab" href="#group" role="tab"
            :class="$route.query.tab == 'grp' ? 'active' : ''" @click="gettab('group')" aria-controls="email"
            aria-selected="true">
            Groups</a>
        </li>
      </ul>

      <div class="add-msg">
        <a v-if="roombtn" @click="RoomModal" data-dismiss="modal" aria-label="Close" data-toggle="modal"
          data-target="#Modal2"><img class="img-fluid" src="../../assets/img/adds.svg" alt="" /></a>
        <a v-if="filterbtn" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#filter"><img
            class="img-fluid" src="../../assets/img/room.svg" alt="" /></a>
      </div>
      <div class="tab-content">
        <div id="agent" class="tab-pane" :class="$route.query.tab == 'chat' ? 'active' : ''">
          <div class="chat-list simplebar agentchatlist" id="myElement7" style="overflow-y: scroll">
            <div v-for="agent in agents" :key="agent.id" :id="'new_message_' + agent.id">
              <div class="media" :style="is_selected_agent(agent) ? 'box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);' : ''" :class="'media_' + agent.id" :id="'agentchat' + agent.id" @click="getmessages(agent)">
                <img v-if="agent.picture" :src="agent.picture" class="align-self-start mr-3" alt="" />
                <img v-else src="../../assets/img/profile.svg" class="align-self-start mr-3" alt="" />
                <span class="online"></span>
                <div class="media-body">
                  <h5>{{ agent.name }}</h5>

                  <div class="listbottom">
                    <a class="fb" href="#"></a>
                    <p :class="'agentmark_' + agent.id">
                      <span :class="'markcount_' + agent.id" v-if="agent.totalUnread > 0">{{ agent.totalUnread }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <infinite-loading @infinite="getAgents"></infinite-loading> -->
          </div>
        </div>
        <div id="group" class="tab-pane" :class="$route.query.tab == 'grp' ? 'active' : ''">
          <div class="chat-list simplebar groups" id="myElement7" style="overflow-y: scroll">
            <div v-for="(groupsdata, index) in groups" :key="groupsdata.id">
              <div class="media" :class="'media_group' + groupsdata.id" @click="
                getGroupMessages(
                  groupsdata.name,
                  groupsdata.id,
                  groupsdata.user_id,
                  index
                )
                ">
                <img src="../../assets/img/profile.svg" class="align-self-start mr-3" alt="" />
                <span class="online"></span>
                <div class="media-body">
                  <h5>{{ groupsdata.name }}</h5>
                  <div class="listbottom">
                    <a class="fb" href="#">{{
                      groupsdata.created_at | formatenotidate
                    }}</a>
                    <p :class="'mark_' + groupsdata.id">
                      <span :class="'markcount_' + groupsdata.id" v-if="groupsdata.count > 0">{{ groupsdata.count
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chatbox-area">
      <div class="adf">
        <div class="msg-head">
          <div class="media">
            <span class="chat-icon"><i class="fa fa-angle-left"></i></span>
            <img v-if="agent.picture" src="../../assets/img/active-user.png" class="align-self-start mr-3" alt="..." />

            <div class="media-body">
              <h5 v-if="agent.name" class="mt-0">{{ agent.name }}</h5>
              <h5 v-if="group.name" class="mt-0">{{ group.name }}</h5>
            </div>
          </div>
        </div>
        <div class="msg-setting" v-if="group.name">
          <ul>
            <li>
              <div class="navbar">
                <div class="dropdown">
                  <a v-if="group.ownerid == this.$storage.get('auth').user.id" class="msgsct-user dropdown-toggle"
                    href="#" id="navbarDropdown" role="button" data-toggle="modal" data-target="#leadAssign"
                    aria-haspopup="true" aria-expanded="true"><img class="img-fluid" src="../../assets/img/profile.svg"
                      alt="" />
                    <span v-if="assignedmemberstr">{{
                      assignedmemberstr
                    }}</span></a>
                  <a v-else class="msgsct-user dropdown-toggle" href="#" id="navbarDropdown"><img class="img-fluid"
                      src="../../assets/img/profile.svg" alt="" />
                    <span v-if="assignedmemberstr">{{
                      assignedmemberstr
                    }}</span></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--message area for a one to one chat-->
      <div class="message-content CommunicationSingleSection" id="CommunicationSmsSection" style="height: %"
        v-if="agent.name">
        <div class="msg-body message-contentss">
          <ul :class="'chat' + agent.id">
            <li v-for="message in messages" :key="message.id" :class="message.msg_from == $storage.get('auth').user.id
              ? 'repaly text-right'
              : 'sender'
              ">
              <span class="name">
                {{ message.created_at | GroupchatDate }}</span>
              <p>
                <span v-if="message.message">{{ message.message }}</span>
                <br />
                <img class="chatImage" :data-image="message.image" v-if="message.image" :src="message.image"
                  style="height: 200px" />
                <!-- <VuePureLightbox
                  v-if="message.image"
                  :thumbnail="message.image"
                  :images="[message.image]"
                /> -->
              </p>
            </li>
          </ul>
        </div>
      </div>
      <!--message area for group chat-->
      <div class="message-content CommunicationGroupSection" id="CommunicationSmsSection" style="height: 65%"
        v-if="group.name">
        <div class="msg-body message-contentss messagess">
          <ul :id="'groupmessages_' + group.id" class="messagess">
            <li v-for="message in groupMessages" :key="message.id"
              :class="message.msg_from == auth ? 'repaly text-right' : 'sender'">
              <span class="name">{{ message.name }} <br />
                {{ message.created_at | GroupchatDate }}</span>
              <p>
                <span v-if="message.message">{{ message.message }}</span>
                <br />
                <img class="chatImage" :data-image="message.image" v-if="message.image" :src="message.image"
                  style="height: 200px" />
                <!-- <VuePureLightbox
                  v-if="message.image"
                  :thumbnail="message.image"
                  :images="[message.image]"
                /> -->
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="send-box" v-if="group.name || agent.name">
        <form @submit.prevent="sendMessage" enctype="multipart/form-data">
          <div class="">
            <VEmojiPicker v-show="show_emoji" @select="select_emoji" :emojiWithBorder="false" />
          </div>

          <div style="position: relative;">
            <div style="position:absolute; top:20;left:20;">
              <div style="cursor:pointer; padding:10px; border-right:1px solid rgb(193,193,193); font-size:18px;"
                class="emoji-button" @click="toggle_emojis" bis_skin_checked="1" >😄</div>
            </div>
          </div>

          <input type="text" style="padding-left: 55px;" v-model="message" id="messagebox" class="form-control"
            placeholder="Write message" />

          <div class="send-btns">
            <div class="row">
              <div class="col-md-7">
                <div class="attach">
                  <div class="button-wrapper">
                    <span class="label">
                      <img class="img-fluid" src="../../assets/img/aad.svg" alt="" />
                      Attach File </span><input type="file" name="upload" ref="imageUploader" @click="resetImageUploader"
                      id="upload" v-on:change="onFileChange" class="upload-box" placeholder="Upload File" />
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="email-sends">
                  <button type="submit" :disabled="btnLoader" class="smssend">
                    <span v-if="btnLoader"><span class="spinner-grow spinner-grow-sm"></span>
                      Loading...</span>
                    <span v-if="!btnLoader" style=" display:flex; align-items:center; gap: 5px;"><img class="img-fluid" src="../../assets/img/sms-sned.svg" style="margin-right:5px;" alt="" />
                      Send</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- newroom modal -->
    <div class="modal fade newroom-modal" id="openroomModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Create a New Room
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><img class="img-fluid" src="../../assets/img/23. Closex.svg" alt="" /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="new-room">
              <form @submit.prevent="createRoom" class="test">
                <div class="form-group">
                  <label for="inputAddress">Room Name</label>
                  <input v-model="room.title" type="text" class="form-control" id="inputAddress" required
                    placeholder="Room Name" />
                </div>
                <div class="form-group">
                  <multi-select :searchable="true" id="a_users" :alreadySelected="newassign.to" textColumn="text"
                    placeholder="Select users" v-model="newassign.to" :options="allEmployees"
                    label="Add People"></multi-select>
                </div>

                <div class="join">
                  <button type="submit" class="btn-block btn-create">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="leadAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Assign Members</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><img class="img-fluid" src="../../assets/img/23. Closex.svg" alt="" /></span>
            </a>
          </div>
          <form v-on:submit.prevent="assignToUser">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <multi-select :searchable="true" id="aa_users" :alreadySelected="assign.to" textColumn="text"
                      placeholder="Select users" v-model="assign.to" :options="allEmployees" label="Users"></multi-select>

                    <!-- <multi-select :searchable="true" id="a_users" :alreadySelected="assign.to" textColumn="text" placeholder="Select users" v-model="assign.to" :options="allEmployees" label="Users"></multi-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"><img class="img-fluid"
                  src="../../assets/img/22. Delete.svg" alt="" />
                Cancel</a>
              <button type="submit" class="rem-btn">
                <img class="img-fluid" src="../../assets/img/23. Close.svg" alt="" />
                Assign
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade newroom-modal" id="filter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Filter Messages
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><img class="img-fluid" src="../../assets/img/23. Closex.svg" alt="" /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="new-room">
              <form class="test">
                <div class="form-group">
                  <select class="form-control" v-model="filtervalue" @change="onChangeFilter">
                    <option value="new" selected>New</option>
                    <option value="old">Old</option>
                  </select>
                </div>
                <div class="join">
                  <!-- <button type="submit" class="rem-btn">Filter</button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----open image -->
    <div class="modal fade" id="openChatImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 data-v-56707086="" class="modal-title">&nbsp;</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><img class="img-fluid" src="../../assets/img/23. Closex.svg" alt="" /></span>
            </a>
          </div>

          <div class="modal-body">
            <img id="bigImagePlaceHolder" :src="chatImage" style="width: 100%" />
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal" aria-label="Close" class="rem-btn close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- newroom modal -->
    <audio ref="audio" src="../../assets/beep/beep.mp3"></audio>
  </div>
</template>
<script>
import axios from "axios";
// import InfiniteLoading from "vue-infinite-loading";
import $ from "jquery";
import moment from 'moment'
// import firebase from "firebase";
// import "firebase/firebase-messaging";
import MultiSelect from "../helpers/MultiSelect";
// import VuePureLightbox from "vue-pure-lightbox";
// import "vue-pure-lightbox/dist/VuePureLightbox.css";
// import VueTagsInput from "@johmun/vue-tags-input";
import sound from "../../assets/beep/beep.mp3";
import { VEmojiPicker } from 'v-emoji-picker';
export default {
  components: {
    // InfiniteLoading,
    MultiSelect,
    VEmojiPicker,
    //VuePureLightbox,
    // VueTagsInput
  },
  data() {
    return {
      chatImage: "",
      file: "",
      roombtn: false,
      filterbtn: true,
      filtervalue: "",
      agents: [],
      searchValue: "",
      tab: "agent",
      assignedmemberstr: "",
      auth: this.$storage.get("auth").user.id,
      groupmembers: [3, 7],
      groupMessages: [],
      groups: [],
      group: {},
      nextpage: 1,
      agent: {},
      message: "",
      messages: [],
      room: {},
      btnLoader: false,
      allEmployees: [],
      assign: {
        to: [],
        lead_id: 0,
        alreadyAssigned: this.alreadyAssignedUsers,
      },
      newassign: {
        to: [],
        lead_id: 0,
        alreadyAssigned: this.alreadyAssignedUsers,
      },
      alreadyAssignedUsers: [],
      assig_usres_str: "",
      message_text: "",
      show_emoji: false,
      emoji: {
        show: false,
        query: "#message",
        cursor: {
          last: null,
          current: null
        }
      }
    };
  },
  created() {
    if (this.$route.query.tab == "grp") {
      this.roombtn = true;
      this.filterbtn = false;
    }
    if (this.$route.query.tab == "chat") {
      this.roombtn = false;
      this.filterbtn = true;
    }
    this.getGroups();
    this.getAgents();
    this.getAllEmployees();
  },
  // updated() {
  //   let v = this;

  //   const messaging = firebase.messaging();
  //   messaging.onMessage(function (payload) {
  //     console.log("Receiving foreground message");
  //     console.log(payload);
  //     console.log(payload.data.message);
  //     if (payload.data.type == "groupchatnoti") {
  //       v.$root.$emit("action", "notifications");
  //       return false;
  //     }
  //     if (payload.data.chat == "group") {
  //       v.groupMessages.push({
  //         message: payload.data.message,
  //         image: payload.data.image,
  //         msg_from: payload.data.msg_from,
  //       });
  //       v.$refs.audio.play();
  //       setTimeout(() => {
  //         v.scrollToEndGroupContainer();
  //       });
  //     } else {
  //       v.$refs.audio.play();

  //       const below = document.getElementById(
  //         "new_message_" + payload.data.msg_from
  //       );
  //       const parent = below.parentNode;
  //       parent.removeChild(below);
  //       parent.prepend(below);
  //       var count = $(".listbottom #count_" + payload.data.msg_from).text();
  //       console.log(count);
  //       var totalcount = 0;
  //       totalcount = Number(count) + 1;
  //       var cl = "new_message_" + payload.data.msg_from;
  //       $(cl + ".listbottom").html("<p><span>" + totalcount + "</span></p>");
  //       if (v.agent.name) {
  //         axios
  //           .get("read/message/" + v.agent.id, {
  //             headers: {
  //               Authorization: "Bearer " + v.$storage.get("auth").token,
  //             },
  //           })
  //           .then((res) => {
  //             if (res.data.success) {
  //               v.agent.totalUnread = 0;
  //             }
  //           });
  //       }
  //       console.log(payload.data.msg_to);
  //       $(".listbottom #count_" + payload.data.msg_from).text(totalcount);
  //       // v.messages.push({
  //       //   message: payload.data.message,
  //       //   image: payload.data.image,
  //       //   msg_from: payload.data.msg_from,
  //       //   msg_to: payload.data.msg_to,
  //       // });

  //       const noteTitle = payload.data.notification.title;
  //       const noteOptions = {
  //         body: payload.data.notification.body,
  //         icon: payload.data.notification.icon,
  //       };
  //       var notification = new Notification(noteTitle, noteOptions);
  //       notification.onclick = function (event) {
  //         notification.close();
  //         console.log(event);
  //         v.$router.push("/chat?tab=grp");
  //       };

  //       setTimeout(() => {
  //         v.scrollToEndSingleContainer();
  //       });
  //     }
  //   });
  // },
  mounted() {
    //open chat image
    $(document).on("click", ".chatImage", function () {
      $("#bigImagePlaceHolder").attr("src", "");
      $("#bigImagePlaceHolder").attr("src", $(this).data("image"));
      $("#openChatImage").modal("show");
    });

    $(".msgsct").click(function () {
      $(".msgsct").removeClass("active");
      $(this).addClass("active");
    });

    $(".chat-icon").click(function () {
      $(".chatbox-area").removeClass("showbox");
    });
    let v = this;
    window.Echo.channel("Groupchat") //Should be Channel Name
      .listen("GroupChat", (e) => {
        console.log(e);
        if (e.type.type == "groupchat") {
          e.type.members.forEach((data) => {
            // console.log(data);
            if (data == this.$storage.get("auth").user.email) {
              // console.log("inside" + data);
              // var message = "";
              // message += "<li class='sender'>";
              // message += "<span  class='name'>";
              // message += "" + e.type.sender_name + "";
              // message += "<br>" + e.type.created_at + "";
              // message += "</span>";
              // message += "<p>";
              // if (e.type.message != "") {
              //   message += "<span>" + e.type.message + "</span><br>";
              // }
              // if (e.type.image != "") {
              //   message +=
              //     "<img src='" +
              //     e.type.image +
              //     "' alt='' class='chatImage' data-image='" +
              //     e.type.image +
              //     "' style='height: 200px'>";
              // }
              // message += "</p>";
              // message += "</li>";
              // $("#groupmessages_" + e.type.group_id).append(message);

              if (e.type.group_id == this.group.id) {
                v.groupMessages.push({
                  message: e.type.message,
                  image: e.type.image,
                  msg_from: e.type.msg_from,
                  name: e.type.sender_name,
                  created_at: e.type.created_at,
                });
                let created_at_date = moment(String(e.type.created_at)).format("MM/DD/YYYY hh:mm A");
                console.log({ created_at_date, created_at: e.type.created_at, e: e });
              }

              if ($(".media_" + e.type.group_id).length > 0) {
                $(".groups").prepend($(".media_group" + e.type.group_id));
                $(".mark_" + e.type.group_id).show();
                // var count = $(".mark_" + e.type.group_id).text();
                // console.log(count);
                // count = eval(count) + 1;
                if (e.type.group_id != this.group.id) {
                  $(".mark_" + e.type.group_id).html(
                    "<span style='  background: #19cd9d;padding: 0px 8px;border-radius: 9px;color: #fff;font-weight: 700;font-size: 12px;'></span>"
                  );
                }
              }
              // var favicon = "../assets/img/newchaticon.png";
              // $("#favicon").attr("href", favicon);
              if (this.$route.name == "Chat") {
                const audio = new Audio(sound);
                audio.play();
                axios
                  .get("read/groupmessage/" + e.type.group_id, {
                    headers: {
                      Authorization:
                        "Bearer " + this.$storage.get("auth").token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      console.log(res.data.success);
                    }
                  });
              }
              var div = $(".CommunicationGroupSection");
              div.scrollTop(div.prop("scrollHeight"));
            }
          });
        }
      });

    window.Echo.channel("Singlechat") //Should be Channel Name
      .listen("SingleChat", (e) => {
        //console.log(e);
        if (e.type.type == "singlechat") {
          if (e.type.email == this.$storage.get("auth").user.email) {
            // console.log("inside");
            // var message = "";
            // message += "<li class='sender'>";
            // message += "<span  class='name'>";
            // message += "<br>" + e.type.created_at + "";
            // message += "</span>";
            // message += "<p>";
            // if (e.type.message != "") {
            //   message += "<span>" + e.type.message + "</span><br>";
            // }
            // if (e.type.image != "") {
            //   message +=
            //     "<img src='" +
            //     e.type.image +
            //     "' alt='' class='chatImage' data-image='" +
            //     e.type.image +
            //     "' style='height: 200px>";
            // }
            // message += "</p>";
            // message += "</li>";
            // $(".chat" + e.type.user_id).append(message);
            if (e.type.user_id == this.agent.id) {
              v.messages.push({
                message: e.type.message,
                image: e.type.image,
                msg_from: e.type.user_id,
                msg_to: e.type.msg_to,
                created_at: e.type.created_at,
              });
            }

            if ($("#agentchat" + e.type.user_id).length > 0) {
              console.log("ss");
              $(".agentchatlist").prepend($("#agentchat" + e.type.user_id));
              $(".agentmark_" + e.type.user_id).show();
              // var count = $(".mark_" + e.type.group_id).text();
              // console.log(count);
              // count = eval(count) + 1;
              if (e.type.user_id != this.agent.id) {
                $(".agentmark_" + e.type.user_id).html(
                  "<span style='  background: #19cd9d;padding: 0px 8px;border-radius: 9px;color: #fff;font-weight: 700;font-size: 12px;'></span>"
                );
              }
            }
            // var favicon = "../assets/img/newchaticon.png";
            // $("#favicon").attr("href", favicon);
            if (this.$route.name == "Chat") {
              if (e.type.email == this.$storage.get("auth").user.email) {
                const audio = new Audio(sound);
                audio.play();
              }
              axios
                .get("read/message/" + this.agent.id, {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    this.agent.totalUnread = 0;
                  }
                });
            }
            setTimeout(() => {
              this.scrollToEndSingleContainer();
            });
            var div = $(".CommunicationSingleSection");
            div.scrollTop(div.prop("scrollHeight"));
          }
        }
      });
  },
  methods: {
    is_selected_agent(agent){
      return this.agent.id == agent.id;
    },
    toggle_emojis() {
      this.emoji.cursor.last = $("#messagebox")[0].selectionStart;
      this.show_emoji = !this.show_emoji;
    },
    select_emoji(emoji) {

      this.emoji.cursor.last = $("#messagebox")[0].selectionStart;

      var currentValue = $("#messagebox").val();
      var newValue = currentValue.slice(0, this.emoji.cursor.last) + emoji.data + currentValue.slice(this.emoji.cursor.last);
      // console.log({ emoji, newValue, message : this.message });
      this.message = newValue;
      // Set the cursor position after inserting the emoji
      this.emoji.cursor.last += emoji.data.length;
      // Focus on the input field again
      $("#messagebox")[0].selectionStart = this.emoji.cursor.last;

      $("#messagebox").focus();
    },
    resetImageUploader() {
      this.$refs.imageUploader.value = "";
    },
    assignToUser() {
      this.assign.alreadyAssigned = this.alreadyAssignedUsers;
      axios
        .post(
          "assiggroupmember",
          { group: this.group.id, assign: this.assign },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.alreadyAssignedUsers = res.data.new_assigned;
            this.assignedmemberstr = res.data.members.assigned_user_str;
            $("#leadAssign").modal("hide");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onFileChange(e) {
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
      if (this.tab == "agent") {
        this.sendMessage();
      } else {
        this.sendGroupMessage();
      }
    },
    onChangeFilter() {
      if (this.filtervalue) {
        this.btnLoader = true;
        axios
          .get("filter/messages/" + this.filtervalue, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.agents = res.data.data;
              this.agent = res.data.data[0];
              this.getmessages(this.agent);
              $("#filter").modal("hide");
              this.btnLoader = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoader = false;
          });
      }
    },
    scrollToEndSingleContainer: function () {
      var container = this.$el.querySelector(".CommunicationSingleSection");
      container.scrollTop = container.scrollHeight;
    },
    scrollToEndGroupContainer: function () {
      var container = this.$el.querySelector(".CommunicationGroupSection");
      container.scrollTop = container.scrollHeight;
    },
    scrollToEnd() {
      window.scrollTo(0, 99999);
    },
    gettab(val) {
      this.groupMessages = [];
      this.messages = [];
      if (val == "group") {
        this.agent = {};
        this.assigned_user_str = "";
        this.messages = [];
        this.roombtn = true;
        this.filterbtn = false;
        const url = new URL(location.href);
        url.searchParams.set("tab", "grp");
        history.pushState(null, "", url);
      } else {
        this.group = {};
        this.assigned_user_str = "";
        this.groupMessages = [];
        this.roombtn = false;
        this.filterbtn = true;
        const url = new URL(location.href);
        url.searchParams.set("tab", "chat");
        history.pushState(null, "", url);
      }
      this.tab = val;
    },
    search() {
      if (this.searchValue) {
        axios
          .get("search", {
            params: {
              search: this.searchValue,
              tab: this.tab,
            },
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              console.log("seach");
              if (res.data.tab == "agent") {
                this.agents = res.data.agent;
              } else {
                this.groups = res.data.group;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.agents = [];
        this.groups = [];
        if (this.tab == "agent") {
          this.getAgents();
        } else {
          this.getGroups();
        }
      }
    },
    getGroupMessages(name, id, groupOwnerId, index) {
      this.groupMessages = [];
      this.messages = [];
      $(".mark_" + id).html("");
      this.agent = {};
      this.group = {};
      this.group.name = name;
      this.group.id = id;
      this.group.ownerid = groupOwnerId;
      $(".media").removeClass("active");
      $(".media_group" + id).addClass("active");
      setTimeout(() => {
        $(".media_group" + id).addClass("active");
      },200);
      $(".chatbox-area").addClass("showbox");

      axios
        .get("get/group/message/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.groupMessages = data.message;
            this.assignedmemberstr = data.assigned_users.assigned_user_str;
            var au = data.assigned_users.assigned_users.split(",");
            console.log(au);
            let vm = this;
            vm.alreadyAssignedUsers = [];
            vm.assign.to.splice(0);
            $.each(au, function (i, v) {
              var aua = v.split("|");
              if (aua) {
                vm.alreadyAssignedUsers.push({ id: aua[0], text: aua[1] });
                vm.assign.to.push({ id: aua[0], text: aua[1] });
              }

              //vm.assign.to.push({id:au_ids[i],text:v})
            });
            setTimeout(() => {
              this.scrollToEndGroupContainer();
            });
            this.$nextTick(() => {
              axios
                .get("read/groupmessage/" + this.group.id, {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    console.log(index);
                    //this.groups[index].count = 0;
                    $(".markcount_" + this.group.id).remove();
                  }
                });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGroups() {
      axios
        .get("get/groups", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.groups = res.data.groups;

            if (this.$route.query.tab == "grp" && this.$route.query.groupid) {
              res.data.groups.forEach((data, index) => {
                if (data.id == this.$route.query.groupid) {
                  this.group = res.data.groups[index];
                  this.getGroupMessages(data.name, data.id, data.user_id);
                }
              });
            } else if (this.$route.query.tab == "grp") {
              console.log(res.data.groups);
              this.group = res.data.groups[0];
              this.getGroupMessages(
                this.group.name,
                this.group.id,
                this.group.user_id
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllEmployees() {
      axios
        .get("all_employees/2", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.id, text: value.name });
              });
              this.allEmployees = dd;
              //console.log(this.allEmployees);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    RoomModal() {
      this.newassign.to.push({
        id: this.$storage.get("auth").user.id,
        text: this.$storage.get("auth").user.name,
      });
      $("#openroomModal").modal("show");
    },
    createRoom() {
      axios
        .post(
          "/create/room",
          { room: this.room, assign: this.newassign },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.btnLoader = false;
          this.newassign.to = [];
          this.room = {};
          $("#openroomModal").modal("hide");
          this.getGroups();
        })
        .catch((error) => {
          console.log(error);
          this.btnLoader = false;
        });
    },
    sendMessage() {
      if (this.message == "" && this.file == "") {
        this.$toast.open({
          position: "top-right",
          message: "Please type message or select file",
          type: "info",
        });
        this.btnLoader = false;
        return false;
      } else {
        this.btnLoader = true;
        if (this.agent.name) {
          const config = {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          };
          let formData = new FormData();
          formData.append("to", this.agent.id);
          formData.append("message", this.message);
          formData.append("file", this.file);
          //   formData.append("lead_id", this.lead.l_id);
          axios
            .post("/send/message", formData, config)
            .then((res) => {
              if (res.data.success) {
                $(".agentchatlist").prepend($("#agentchat" + this.agent.id));
                this.btnLoader = false;
                this.message = "";
                this.file = "";
                this.messages.push({
                  message: res.data.message,
                  image: res.data.image,
                  msg_from: res.data.msg_from,
                  msg_to: res.data.msg_to,
                  created_at: res.data.created_at,
                });
                var div = $(".CommunicationSingleSection");
                div.scrollTop(div.prop("scrollHeight"));
                setTimeout(() => {
                  this.scrollToEndSingleContainer();
                });
              } else {
                this.btnLoader = false;
              }
            })
            .catch((error) => {
              console.log(error);
              this.btnLoader = false;
            });
        } else {
          this.sendGroupMessage();
        }
      }
    },
    sendGroupMessage() {
      if (this.message == "" && this.file == "") {
        this.$toast.open({
          position: "top-right",
          message: "Please type message or select file",
          type: "info",
        });
        this.btnLoader = false;
        return false;
      } else {
        this.btnLoader = true;
        const config = {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        };
        let formData = new FormData();
        formData.append("message", this.message);
        formData.append("file", this.file);
        formData.append("groupid", this.group.id);
        axios
          .post("/send/group/message", formData, config)
          .then((res) => {
            if (res.data.success) {
              $(".groups").prepend($(".media_group" + this.group.id));
              this.btnLoader = false;
              this.message = "";
              this.file = "";
              this.groupMessages.push({
                message: res.data.message,
                image: res.data.image,
                msg_from: res.data.msg_from,
                name: res.data.sender_name,
                created_at: res.data.created_at,
              });
              setTimeout(() => {
                this.scrollToEndGroupContainer();
              });
            }
            this.btnLoader = false;
          })
          .catch((error) => {
            console.log(error);
            this.btnLoader = false;
          });
      }
    },
    getmessages(agent) {
      this.groupMessages = [];
      this.messages = [];
      this.agent = agent;
      $(".media").removeClass("active");
      $(".media_" + this.agent.id).addClass("active");
      setTimeout(() => {
        $(".media_" + this.agent.id).addClass("active");
      },200);
      $(".chatbox-area").addClass("showbox");
      $(".send-box").css("margin-top: 5%");
      $(".removecount_" + this.agent.id).text("");
      $(".agentmark_" + this.agent.id).text("");
      axios
        .get("get/message/" + this.agent.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.messages = data.success;
            setTimeout(() => {
              this.scrollToEndSingleContainer();
            });
            this.$nextTick(() => {
              axios
                .get("read/message/" + this.agent.id, {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    this.agent.messages_count = 0;
                  }
                });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAgents() {
      axios
        .get("get/agents", {
          params: {
            page: this.nextpage,
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.agents = res.data.success;
            if (this.$route.query.tab == "chat" && this.$route.query.userid) {
              res.data.success.forEach((data, index) => {
                if (data.id == this.$route.query.userid) {
                  console.log(data);
                  this.agent = res.data.success[index];
                  this.getmessages(this.agent);
                }
              });
            } else if (this.$route.query.tab == "chat") {
              this.agent = res.data.success[0];
              this.getmessages(this.agent);
            }
          }
        });

      // });
    },
  },
};
</script>
<style scoped>
.name {
  font-weight: 400;
  font-size: 12px;
  line-height: 2;
  color: #7d7d7d;
  display: inherit;
}

.adf {
  height: 74px;
}

.smssend {
  text-decoration: none;
  display: flex;
  border: 1px solid #3151a1;
  background: #3151a1;
  padding: 8px 20px;
  border-radius: 10px;
  text-transform: capitalize;
  line-height: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
}

.btn-create {
  text-decoration: none;

  border: 1px solid #3151a1;
  background: #3151a1;
  padding: 8px 20px;
  border-radius: 10px;
  text-transform: capitalize;
  line-height: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.navbar {
  position: relative !important;
  bottom: -2px;
}

.message-area .nav-tabs {
  border: none;
}

.message-area .nav-tabs .nav-link {
  background: #dbe5ff;
  border-radius: 30px;
  color: #3151a1;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px 20px;
  text-decoration: none;
  position: relative;
  margin-bottom: 0;
  border: none;
  display: block;
  margin-right: 10px;
}

.message-area .nav-tabs li {
  width: 50%;
  display: inline-block;
}

.message-area .nav-tabs .nav-item.show .nav-link,
.message-area .nav-tabs .nav-link.active {
  background: #3151a1 !important;
  color: #fff;
}

.chat-list .media {
  margin: 20px 10px;
  position: relative;
  padding: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  background: #f1f1f1;
}

.chat-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.chat-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.chat-list::-webkit-scrollbar-thumb {
  background-color: #666;
}

#CommunicationSmsSection {
  height: 70%;
}

.lightbox {
  z-index: 99999 !important;
}

a.msgsct-user {
  max-width: 220px;
  overflow: hidden;
}

.send-box {
  padding: 15px 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style> 

